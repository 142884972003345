exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-overview-js": () => import("./../../../src/pages/business-overview.js" /* webpackChunkName: "component---src-pages-business-overview-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relationship-index-js": () => import("./../../../src/pages/investor-relationship/index.js" /* webpackChunkName: "component---src-pages-investor-relationship-index-js" */),
  "component---src-pages-privacy-policies-js": () => import("./../../../src/pages/privacy-policies.js" /* webpackChunkName: "component---src-pages-privacy-policies-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-announcements-js": () => import("./../../../src/templates/announcements.js" /* webpackChunkName: "component---src-templates-announcements-js" */),
  "component---src-templates-financial-reports-js": () => import("./../../../src/templates/financial-reports.js" /* webpackChunkName: "component---src-templates-financial-reports-js" */)
}

